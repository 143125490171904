
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { linkTo, enter, leave } from '@/layouts/side-menu'
import context from '@cloudfun/core'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu
    // DarkModeSwitcher,
  },
  setup () {
    const application = context.current
    const policy = application?.policy
    const route = useRoute()
    const router = useRouter()
    const formattedMenu = ref([])
    const simpleMenu = computed(() => policy?.makeMenu('/simple-menu') || [])
    const configuration = ref({ AdminTitle: '能源協會後台系統' })

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(simpleMenu.value)
      }
    )

    const adminTitle = computed(() => application?.model.state.configuration.value.AdminTitle || "Cloudfun Admin")

    onMounted(async () => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('app')
      formattedMenu.value = $h.toRaw(simpleMenu.value)
      if (application) application.model.dispatch('configuration/read')
    })

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      configuration,
      adminTitle
    }
  }
})
